.waitlist-form {
  &__email {
    margin-bottom: 16px;
  }
  &__errors li {
    color: $color-error;
  }
  &__success {
    padding: 35px 10px;
    width: 100%;
  }
}
